<template>
  <watetReharge :priceList="priceList" :types="types"></watetReharge>
</template>
<script>
import watetReharge from '../water-recharge/Index.vue'
export default {
  data () {
    return {
      types: [8],
      priceList: [
        {
          type: 8,
          price: '',
          number: 1,
          money: '',
          name: '',
          remark: '',
          nameFlag: true,
          title: '自定义收费'
        },
      ]
    }
  },
  components: {
    watetReharge
  }
}
</script>